import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const Commitment = props => (
  <Section aside title='Our Commitment' {...props}>
    <Card variant='paper'>
      <Text variant='p'>
        We are committed to providing our viewers with the best possible user experience. If you encounter any issues with the website or have any have questions in general, please do not hesitate to contact us.
      </Text>
    </Card>
  </Section>
)

export default Commitment
